import {workflowTemplates} from "../helpers/data";
import axios from "axios";
import * as API_URL from "../configs/APIUrl";
import {WORKFLOW_CHAT} from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class WorkflowService {

    static createWorkflow = data =>
        privateAPI.post(API_URL.CREATE_WORKFLOW, data,
            {
                headers: {"Content-Type": "multipart/form-data"}
            }
        )

    static createTextToWorkflow = data =>
        privateAPI.post(API_URL.CREATE_TEXT_TO_WORKFLOW_URL, data);

    static updateTextToWorkflow = data =>
        privateAPI.post(API_URL.UPDATE_TEXT_TO_WORKFLOW_URL, data);

    static aiRedraw = data =>
        privateAPI.post(API_URL.WORKFLOW_AI_REDRAW_URL, data);

    static getWorkflows = params =>
        privateAPI.get(API_URL.GET_ALL_WORKFLOW, {params});

    static getWorkflowByTopicName = (companyName, params) =>
        privateAPI.get(`${API_URL.GET_WORKFLOW_BY_TOPIC}/${companyName}`, {params});


    static deleteMultipleWorkflow = data =>
        privateAPI.post(API_URL.DELETE_MULTIPLE_WORKFLOW, data,
            {
                headers: {"Content-Type": "application/json"}
            }
        )

    static getWorkflowTemplates = async () => (
        workflowTemplates
    )

    static duplicateWorkflow = data =>
        privateAPI.post(API_URL.DUPLICATE_WORKFLOW, data);

    static getChatbotData = (bodyData, chatbotId, apiKey) => {
        return axios.post(`${WORKFLOW_CHAT}/${chatbotId}`, bodyData, {
            headers: {apiKey},
        });
    }

}
