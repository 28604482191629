import * as PATH from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";

export default class WhatsAppService {

    static registerWhatsapp = data =>
        privateAPI.post(PATH.REGISTER_WHATSAPP_URL, data, {
            headers: {"Content-Type": "application/x-www-form-urlencoded"}
        });

    static getWhatsappList = async params =>
        privateAPI.get(PATH.GET_ALL_WHATSAPP_URL, {params});

    static whatsappBotToggle = async data =>
        privateAPI.put(PATH.WHATSAPP_TOGGLE_URL, data);

    static deleteWhatsapp = async data => {
        return privateAPI.delete(PATH.DELETE_WHATSAPP_URL, {data});
    }

    static changeAccessToken = async data =>
        privateAPI.put(PATH.WHATSAPP_CHANGE_ACCESS_TOKEN_URL, data);

}
