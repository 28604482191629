import React, {createContext, useState} from 'react';
import UatFileService from "../services/UatFileService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const OnReviewUatContext = createContext("OnReviewUatContext");

const OnReviewUatContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [onReviewUatList, setOnReviewUatList] = useState([]);

    const [approvedUatHistoryLoading, setApprovedUatHistoryLoading] = useState(false);
    const [approvedUatHistoryTotalElements, setApprovedUatHistoryTotalElements] = useState(0);
    const [approvedUatHistoryList, setApprovedUatHistoryList] = useState([]);

    const getOnReviewUatList = async params => {
        try {

            setLoading(true);

            const res = await UatFileService.getUatForManager(params);
            setOnReviewUatList(res.data.content);
            setTotalElements(res.data.totalElements);

            setApprovedUatHistoryList([]);
            setApprovedUatHistoryTotalElements(0);

            setLoading(false);

        } catch (error) {

            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getApprovedUatHistoryList = async params => {
        try {

            setApprovedUatHistoryLoading(true);

            const res = await UatFileService.getUatForManager(params);
            setApprovedUatHistoryList(res.data.content);
            setApprovedUatHistoryTotalElements(res.data.totalElements);

            setApprovedUatHistoryLoading(false);

        } catch (error) {

            setApprovedUatHistoryLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <OnReviewUatContext.Provider
            value={{
                loading,
                totalElements,
                onReviewUatList,
                getOnReviewUatList,
                approvedUatHistoryLoading,
                approvedUatHistoryTotalElements,
                approvedUatHistoryList,
                getApprovedUatHistoryList,
            }}
        >
            {children}
        </OnReviewUatContext.Provider>
    );
}

export default OnReviewUatContextProvider;
