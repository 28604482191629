import * as PATH from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class UserActivityLogService {

    static getUserActivityLogList = (id, params) =>
        privateAPI.get(`${PATH.GET_USER_ACTIVITY_LOG_LIST}/${id}`, {params});

    static getApiUsageList = (params) =>
        privateAPI.get(PATH.GET_API_USAGE_LIST_URL, {params});

}
