/**
 * Created by WebStorm.
 * User: Mehedi Hasan
 * Date: 26 Jan 2025
 * Time: 3:40 PM
 * Email: mdmehedihasanroni28@gmail.com
 */
import privateAPI from "../rest_handlers/privateAPI";
import * as API_URL from "../configs/APIUrl";

export default class PaymentMethodService {

    static createPaymentMethod = data =>
        privateAPI.post(API_URL.CREATE_PAYMENT_METHOD, data);

    static getAllPaymentMethod = params =>
        privateAPI.get(API_URL.GET_ALL_PAYMENT_METHOD, {params});

    static deletePaymentMethod = data =>
        privateAPI.post(API_URL.DELETE_PAYMENT_METHOD, data);

    static makeDefaultPaymentMethod = data =>
        privateAPI.post(API_URL.MAKE_DEFAULT_PAYMENT_METHOD, data);

}
