import React, {createContext, useState} from 'react';
import {ACCESS_TOKEN, EP_PROFILE, TEMP_USER} from "../helpers/Constant";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";

export const AuthContext = createContext("AuthContext");

const auth = localStorage.getItem(ACCESS_TOKEN);

let tempUserLocalStorage = localStorage.getItem(TEMP_USER);
tempUserLocalStorage = tempUserLocalStorage ? JSON.parse(tempUserLocalStorage) : null

let _profile = localStorage.getItem(EP_PROFILE);
_profile = _profile ? JSON.parse(_profile) : null;

const AuthContextProvider = ({children}) => {

    const [isLogin, setIsLogin] = useState(!!auth);

    const [loading, setLoading] = useState(false);
    const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);

    const [changePasswordLoading, setChangePasswordLoading] = useState(false);

    const [profile, setProfile] = useState(_profile);
    const [profileLoading, setProfileLoading] = useState(false);

    const [tempUser, setTempUser] = useState(tempUserLocalStorage);

    const login = async credential => {
        try {

            setLoading(true);

            await AuthService.login(credential);

            const _tempUser = {...credential, otpValidTime: Date.now()}

            localStorage.setItem(TEMP_USER, JSON.stringify(_tempUser));

            setTempUser(_tempUser);

            setLoading(false);

            return true;

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return false;
        }
    }

    const otpVerify = async data => {
        try {

            setOtpLoading(true);

            const res = await AuthService.loginRequestOtpVerify(data);

            localStorage.setItem(ACCESS_TOKEN, res.data.token.access);

            if (!res?.data?.firstLogin && tempUser?.password) {

                setTempUser(null);

                setIsLogin(true);
            }

            setOtpLoading(false);

            return res.data;

        } catch (error) {

            setOtpLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null;

        }
    }


    const changePassword = async data => {
        try {

            setChangePasswordLoading(true);

            await UserService.changePassword(data);

            localStorage.removeItem(TEMP_USER);
            localStorage.removeItem(ACCESS_TOKEN);

            setTempUser(null);

            Toast("success", "Password Changed", "Your password has been changed successfully.")

            setChangePasswordLoading(false);

            return true;

        } catch (error) {

            setChangePasswordLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return false;
        }
    }

    const getProfile = async () => {
        try {

            setProfileLoading(true);

            const res = await UserService.getProfile();

            setProfile(res.data.info);
            localStorage.setItem(EP_PROFILE, JSON.stringify(res.data.info));

            setProfileLoading(false);

            return res.data.info;

        } catch (error) {

            setProfileLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null;
        }
    }

    const forgotPassword = async credential => {
        try {

            setForgotPasswordLoading(true);

            const _tempUser = {...credential, otpValidTime: Date.now()}

            await AuthService.forgotPassword({email: credential.username});

            localStorage.setItem(TEMP_USER, JSON.stringify(_tempUser));

            setTempUser(_tempUser);

            setForgotPasswordLoading(false);

            return true;

        } catch (error) {

            setForgotPasswordLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return false;
        }
    }

    const resendOtp = async () => {
        try {

            setLoading(true);

            const _tempUser = {...tempUser, otpValidTime: Date.now()}

            await AuthService.resendOtp({email: tempUser.username})

            localStorage.setItem(TEMP_USER, JSON.stringify(_tempUser));

            Toast("success", "OTP Sent", "Otp has been resent successfully to your email")

            setTempUser(_tempUser);

            setLoading(false);

            return true;

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return false;
        }
    }

    const logout = async () => {

        setIsLogin(false);

        setTempUser(null);
        setProfile(null);

        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(TEMP_USER);
        localStorage.removeItem(EP_PROFILE);

    }

    return (
        <AuthContext.Provider
            value={{
                isLogin,
                loading,
                profile,
                profileLoading,
                setProfile,
                getProfile,
                loggedUserRoles: [profile?.role?.alias],
                login,
                logout,
                otpVerify,
                otpLoading,
                tempUser,
                resendOtp,
                forgotPassword,
                forgotPasswordLoading,
                changePassword,
                changePasswordLoading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
