import React, {createContext, useState} from 'react';
import SubscriptionService from "../services/SubscriptionService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const SubscriptionContext = createContext("SubscriptionContext");

const SubscriptionContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [subscriptionList, setSubscriptionList] = useState([]);

    const [currentPlanLoading, setCurrentPlanLoading] = useState(false);
    const [currentPlan, setCurrentPlan] = useState(null);

    const [subscriptionPlanLoading, setSubscriptionPlanLoading] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);

    const getAllSubscription = async () => {
        try {

            setLoading(true);

            const order = {Free: 1, Basic: 2, Plus: 3};

            const res = await SubscriptionService.getAllSubscriptionPlan();

            const sortedPlans = res.data.sort((a, b) => order[a.plan_name] - order[b.plan_name]);
            setSubscriptionList(sortedPlans);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getSubscriptionPlanSummary = async params => {
        try {

            setCurrentPlanLoading(true);

            const res = await SubscriptionService.getSubscriptionPlanSummary(params);

            const usagePercent = getUsagePercent(res.data.currentUsage, res.data.usageLimit)

            setCurrentPlan({...res.data, usagePercent});

            setCurrentPlanLoading(false);

        } catch (error) {

            setCurrentPlanLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getSubscriptionPlan = async params => {
        try {

            setSubscriptionPlanLoading(true);

            const res = await SubscriptionService.getSubscriptionPlan(params);
            setSubscriptionPlan(res.data);

            setSubscriptionPlanLoading(false);

            return res.data;

        } catch (error) {

            setSubscriptionPlanLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null

        }
    }

    const getUsagePercent = (usedCount, totalCount) => {

        return (usedCount * 100) / totalCount;

    }

    return (
        <SubscriptionContext.Provider
            value={{
                loading,
                subscriptionList,
                getAllSubscription,
                currentPlanLoading,
                currentPlan,
                getSubscriptionPlanSummary,
                subscriptionPlanLoading,
                subscriptionPlan,
                getSubscriptionPlan,
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    );
}

export default SubscriptionContextProvider;
