import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import LanguageService from "../services/LanguageService";

export const LanguageContext = createContext("LanguageContext");

const LanguageContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [chatbotLanguageList, setChatbotLanguageList] = useState([]);

    const [languageList, setLanguageList] = useState([]);

    const getLanguageListByChatbot = async chatbotId => {
        try {

            setLoading(true);

            const res = await LanguageService.getLanguagesByChatbot(chatbotId);
            setChatbotLanguageList(res.data);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getLanguageList = async (params) => {
        try {

            setLoading(true);

            const res = await LanguageService.getAllLanguage(params);
            setLanguageList(res.data);

            setLoading(false);

            return res.data;

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null
        }
    }

    return (
        <LanguageContext.Provider
            value={{
                loading,
                chatbotLanguageList,
                getLanguageListByChatbot,
                getLanguageList,
                languageList,
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
}

export default LanguageContextProvider;
