import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import WhatsAppService from "../../services/third_party/WhatsAppService";

export const WhatsappContext = createContext("WhatsappContext");

const WhatsappContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [whatsappList, setWhatsappList] = useState([]);

    const getWhatsappList = async params => {
        try {

            setLoading(true);

            const res = await WhatsAppService.getWhatsappList(params);
            setWhatsappList(res.data.data);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <WhatsappContext.Provider
            value={{
                loading,
                whatsappList,
                getWhatsappList,
            }}
        >
            {children}
        </WhatsappContext.Provider>
    );
}

export default WhatsappContextProvider;
