import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import {Layout} from 'antd';
import LoadingSuspense from "../common/LoadingSuspense";
import {Navigate, Route, Routes} from "react-router-dom";
import PageRoutes from "../../routes/PageRoutes";
import {PRODUCTION_PATH, ROOT_PATH, UAT_PATH, USER_LIST_VIEW_PATH} from "../../routes/Slug";
import Page404 from "../../pages/error_pages/Page404";
import {AuthContext} from "../../contexts/AuthContextProvider";
import {hasRole} from "../../helpers/Utils";
import PageContentFooter from "./PageContentFooter";

const AsideLeft = lazy(() => import('./AsideLeft'));

const {Sider, Content} = Layout;

const DefaultLayout = () => {

    const {isLogin, getProfile, loggedUserRoles, profileLoading, profile} = useContext(AuthContext);

    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (isLogin) {
            getProfile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateMap = {
        "EP_SUPER_USER": USER_LIST_VIEW_PATH,
        "OPERATOR": UAT_PATH,
        "MANAGER": PRODUCTION_PATH,
    }

    return (
        <Layout>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                width={240}
                theme="light"
                className="my-sider"
            >
                <Suspense fallback={<LoadingSuspense height="100vh"/>}>

                    {/*{*/}
                    {/*    hasRole(loggedUserRoles, [Roles.LITE_EP_SUPER_USER]) ? <>*/}
                    {/*        {*/}
                    {/*            layoutTwoRoutes.includes(location.pathname.split("/")[1]) ?*/}
                    {/*                <AsideLeftTwo*/}
                    {/*                    setCollapsed={setCollapsed}*/}
                    {/*                    collapsed={collapsed}*/}
                    {/*                /> : <AsideLeft*/}
                    {/*                    setCollapsed={setCollapsed}*/}
                    {/*                    collapsed={collapsed}*/}
                    {/*                />*/}
                    {/*        }*/}
                    {/*    </> : <AsideLeft*/}
                    {/*        setCollapsed={setCollapsed}*/}
                    {/*        collapsed={collapsed}*/}
                    {/*    />*/}
                    {/*}*/}

                    <AsideLeft
                        setCollapsed={setCollapsed}
                        collapsed={collapsed}
                    />


                </Suspense>
            </Sider>

            <Layout>
                {/*<NavHeader/>*/}
                <Content className="app-page">
                    {
                        profileLoading ? <LoadingSuspense/> :
                            <Suspense fallback={<LoadingSuspense/>}>
                                <Routes>
                                    {
                                        PageRoutes.map(route => {

                                            if (!hasRole(loggedUserRoles, route.roles)) {
                                                return null;
                                            }

                                            return <Route
                                                key={route.path}
                                                path={route.path}
                                                element={<route.component/>}
                                            />
                                        })
                                    }
                                    <Route
                                        path={ROOT_PATH}
                                        element={<Navigate
                                            to={profile && navigateMap[profile?.role?.alias]}
                                        />}
                                    />
                                    <Route path="*" element={<Page404/>}/>
                                </Routes>
                            </Suspense>
                    }
                    <PageContentFooter/>
                </Content>
            </Layout>
        </Layout>
    );
}

export default DefaultLayout;
