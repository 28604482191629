export const ROOT_PATH = "/";
export const CHAT_BOT_PATH = `${ROOT_PATH}chatbot-list`;
export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;
export const LOGIN_PATH = `${ROOT_PATH}login`;
export const TWO_FACTOR_VERIFICATION_PATH = `${ROOT_PATH}two-factor-verification`;
export const CREATE_NEW_PASSWORD_PATH = `${ROOT_PATH}create-new-password`;
export const PASSWORD_CREATE_SUCCESS_PATH = `${ROOT_PATH}success`;
export const UAT_PATH = `${ROOT_PATH}uat`;
export const UAT_HISTORY_PATH = `${ROOT_PATH}uat-history`;
export const PRODUCTION_PATH = `${ROOT_PATH}production`;
export const USER_LIST_VIEW_PATH = `${ROOT_PATH}user-list`;
export const USER_ACTIVITY_LOG_PATH = `${ROOT_PATH}user-activity-logs`;
export const SETTINGS_PATH = `${ROOT_PATH}settings`;
export const MONITORING_PATH = `${ROOT_PATH}monitoring`;
export const FORGOT_PASSWORD_PATH = `${ROOT_PATH}forgot-password`;
export const FORGOT_PASSWORD_OTP_PATH = `${ROOT_PATH}otp`;
export const TELEGRAM_BOT_LIST_PATH = `${ROOT_PATH}telegram-bot-list`;
export const WORKFLOW_PATH = `${ROOT_PATH}workflow`;
export const CREATE_WORKFLOW_PATH = `${ROOT_PATH}create-workflow`;
export const UPDATE_WORKFLOW_PATH = `${ROOT_PATH}update-workflow`;
export const WORKFLOW_CHATBOT_PATH = `${ROOT_PATH}web-chat-plugin`;
export const WEB_CONFIG_PATH = `${ROOT_PATH}web-config`;

export const UAT_DRAFT_VERSION_PATH = `${ROOT_PATH}uat-draft-version`;
export const UAT_REVIEWED_VERSION_PATH = `${ROOT_PATH}uat-reviewed-version`;

// lite
export const PRODUCTION_LITE_PATH = `${ROOT_PATH}production-lite`;
export const PRODUCTION_LITE_HISTORY_PATH = `${ROOT_PATH}production-lite-history`;
export const CHATBOT_LITE_PATH = `${ROOT_PATH}chatbot-lite-list`;

//
export const ON_REVIEW_UAT_PATH = `${ROOT_PATH}on-review-uat`;
export const APPROVED_UAT_PATH = `${ROOT_PATH}approved-uat`;
export const APPROVED_UAT_HISTORY_PATH = `${ROOT_PATH}approved-uat-history`;

export const WHATSAPP_LIST_VIEW_PATH = `${ROOT_PATH}whatsapp-list`;

// billing & subscription
export const BILLING_SUBSCRIPTION_PATH = `${ROOT_PATH}billing-subscription`;