import ChatbotContextProvider from "../contexts/ChatbotContextProvider";
import UatFileContextProvider from "../contexts/UatFileContextProvider";
import ProductionFileContextProvider from "../contexts/ProductionFileContext";
import UserContextProvider from "../contexts/UserContextProvider";
import AuthContextProvider from "../contexts/AuthContextProvider";
import LanguageContextProvider from "../contexts/LanguageContextProvider";
import SettingContextProvider from "../contexts/SettingContextProvider";
import UserActivityLogContextProvider from "../contexts/UserActivityLogContextProvider";
import TelegramContextProvider from "../contexts/third_party/TelegramContextProvider";
import WorkflowCanvasContextProvider from "../contexts/WorkflowCanvasContextProvider";
import WorkflowContextProvider from "../contexts/WorkflowContextProvider";
import UatProdFileContextProvider from "../contexts/UatProdFileContextProvider";
import OnReviewUatContextProvider from "../contexts/OnReviewUatContextProvider";
import WhatsappContextProvider from "../contexts/third_party/WhatsappContextProvider";
import SubscriptionContextProvider from "../contexts/SubscriptionContextProvider";
import PaymentHistoryContextProvider from "../contexts/PaymentHistoryContextProvider";
import PaymentMethodContextProvider from "../contexts/PaymentMethodContextProvider";

export const providers = [
    AuthContextProvider,
    ChatbotContextProvider,
    UatFileContextProvider,
    ProductionFileContextProvider,
    UserContextProvider,
    LanguageContextProvider,
    SettingContextProvider,
    UserActivityLogContextProvider,
    TelegramContextProvider,
    WorkflowCanvasContextProvider,
    WorkflowContextProvider,
    UatProdFileContextProvider,
    OnReviewUatContextProvider,
    WhatsappContextProvider,
    SubscriptionContextProvider,
    PaymentHistoryContextProvider,
    PaymentMethodContextProvider,
]