/**
 * Created by WebStorm.
 * User: Mehedi Hasan
 * Date: 27 Jan 2025
 * Time: 3:13 PM
 * Email: mdmehedihasanroni28@gmail.com
 */

import privateAPI from "../rest_handlers/privateAPI";
import * as API_URL from "../configs/APIUrl";

export default class PaymentHistoryService {

    static getAllPaymentHistory = params =>
        privateAPI.get(API_URL.GET_ALL_PAYMENT_HISTORY, {params});

}