import privateAPI from "../rest_handlers/privateAPI";
import * as PATH from "../configs/APIUrl";

export default class UatProdService {

    static createUatProdFile = data =>
        privateAPI.post(PATH.CREATE_UAT_PROD_FILE_URL, data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

    static getUatProdFileList = params =>
        privateAPI.get(PATH.GET_ALL_UAT_PROD_FILE_URL, {params});

    static getUatVersionList = params =>
        privateAPI.get(PATH.GET_ALL_UAT_VERSION_URL, {params});

    static getUatProdVersionHistory = (chatbotId, languageId) =>
        privateAPI.get(`${PATH.GET_ALL_UAT_PROD_VERSION_URL}/${chatbotId}/${languageId}`);

    static getUatProdFileById = id =>
        privateAPI.get(`${PATH.GET_UAT_PROD_FILE_BY_ID_URL}/${id}`);

    static getChatbotPerformance = data =>
        privateAPI.post(PATH.GET_CHATBOT_PERFORMANCE_URL, data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

    static getWorkflowPerformance = data =>
        privateAPI.post(PATH.GET_WORKFLOW_PERFORMANCE_URL, data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

    static downloadUatFile = id =>
        privateAPI.get(`${PATH.DOWNLOAD_UAT_PROD_FILE_URL}/${id}`, {responseType: 'blob'});

    static updateUatProdFile = (data, id) =>
        privateAPI.put(`${PATH.UPDATE_UAT_PROD_FILE_URL}/${id}`, data, {
                headers: {"Content-Type": "multipart/form-data"}
            }
        );

    static publishUatProdFile = id =>
        privateAPI.put(`${PATH.PUBLISH_UAT_PROD_URL}/${id}`);

}
