export const PRIMARY_COLOR = "#24D2FE";
export const PRIMARY_GRADIENT_COLOR = "linear-gradient(287.88deg, #FF1CF7 -163.3%, #00F0FF 137.14%)";
export const DEFAULT_BTN_BGC = "#F5F6F7";
export const PRIMARY_LIGHT_BGC = "#E8F8FF";
export const RED_COLOR = "#DA1E28";
export const RED_BGC = "#DA1E28";
export const GREEN_COLOR = "#24A148";
export const GREEN_BGC = "#EEFFF2";
export const BORDER_COLOR = "#EAEAEA";
export const TXT_PRIMARY_COLOR = "#243757";
export const TEXT_SECONDARY = "#5D6B82";
export const TXT_GRAY_COLOR = "#7A7A7A";
export const BODY_BG_COLOR = "#F7F8FA";
export const WHITE_COLOR = "#FFFFFF";
export const GRAY_2 = "#757575";
export const GRAY_ONE = "#F5F6F7";
export const ASIDE_LEFT_BGC = "#222222";
export const REVIEW_COLOR = "#F1C21B";
export const REVIEW_BGC = "#f8e7b0";


/*
* 78: Page Header Height
* 56: Table Header Height
* 80: Table Pagination Height
* 74: Footer
* 92: Search Card
* */
export const TOTAL_CONTENT_HEIGHT = 78 + 56 + 80 + 74 + 92;
// export const TOTAL_CONTENT_HEIGHT = 80 + 72 + 64 + 64 + 1;