import React, {useContext} from 'react';
import ErrorLayout from "../../components/layout/ErrorLayout";
import {ENTERPRISE_USER_NAVIGATE_MAP} from "../../helpers/Constant";
import {AuthContext} from "../../contexts/AuthContextProvider";

const Page404 = () => {

    const {profile} = useContext(AuthContext);

    return (
        <ErrorLayout
            status={404}
            title={404}
            subTitle="Sorry, the page you visited does not exist."
            btnName="Go To Main Page"
            pathName={ENTERPRISE_USER_NAVIGATE_MAP[profile?.role?.alias]}
        />
    );
}

export default Page404;
