import * as PATH from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class LanguageService {

    static getAllLanguage = (params) =>
        privateAPI.get(PATH.GET_ALL_LANGUAGE_URL, {params});

    static getLanguagesByChatbot = id =>
        privateAPI.get(`${PATH.GET_LANGUAGES_BY_CHATBOT_URL}/${id}`);

}
