import privateAPI from "../rest_handlers/privateAPI";
import * as PATH from "../configs/APIUrl";

export default class UatFileService {

    static createUatFile = data =>
        privateAPI.post(PATH.CREATE_UAT_FILE_URL, data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

    static getUatFileList = params =>
        privateAPI.get(PATH.GET_ALL_UAT_FILE_URL, {
                params,
                paramsSerializer: {indexes: null}
            }
        );

    static getUatForManager = params =>
        privateAPI.get(PATH.GET_ALL_UAT_FOR_MANAGER, {
                params,
                paramsSerializer: {indexes: null}
            }
        );

    static getUatVersionList = params =>
        privateAPI.get(PATH.GET_ALL_UAT_VERSION_URL, {params});

    static getUatFileById = id =>
        privateAPI.get(`${PATH.GET_UAT_FILE_BY_ID_URL}/${id}`);

    static getChatbotPerformance = data =>
        privateAPI.post(PATH.GET_CHATBOT_PERFORMANCE_URL, data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

    static getWorkflowPerformance = data =>
        privateAPI.post(PATH.GET_WORKFLOW_PERFORMANCE_URL, data, {
            headers: {"Content-Type": "multipart/form-data"}
        });

    static downloadUatFile = id =>
        privateAPI.get(`${PATH.DOWNLOAD_UAT_FILE_URL}/${id}`, {responseType: 'blob'});

    static updateUatFile = (data, id) =>
        privateAPI.put(`${PATH.UPDATE_UAT_FILE_URL}/${id}`, data, {
                headers: {"Content-Type": "multipart/form-data"}
            }
        );

    static applyUatVersionPublish = id =>
        privateAPI.post(`${PATH.APPLY_UAT_VERSION_PUBLISH_URL}/${id}`, null);

    static approveUat = (id, params) =>
        privateAPI.post(`${PATH.APPROVE_UAT_URL}/${id}`, null, {params});
}
