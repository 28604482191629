/**
 * Created by WebStorm.
 * User: Mehedi Hasan
 * Date: 27 Jan 2025
 * Time: 3:14 PM
 * Email: mdmehedihasanroni28@gmail.com
 */

import React, {createContext, useState} from 'react';
import PaymentHistoryService from "../services/PaymentHistoryService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const PaymentHistoryContext = createContext("PaymentHistoryContext");

const PaymentHistoryContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [paymentHistoryList, setPaymentHistoryList] = useState([]);

    const getAllPaymentHistory = async params => {
        try {

            setLoading(true);

            const res = await PaymentHistoryService.getAllPaymentHistory(params);
            setPaymentHistoryList(res.data);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <PaymentHistoryContext.Provider
            value={{
                loading,
                paymentHistoryList,
                getAllPaymentHistory,
            }}
        >
            {children}
        </PaymentHistoryContext.Provider>
    );
}

export default PaymentHistoryContextProvider;
