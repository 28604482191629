import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import UatProdService from "../services/UatProdService";

export const UatProdFileContext = createContext("UatProdFileContext");

const UatProdFileContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [uatProdFileList, setUatProdFileList] = useState([]);

    const [uatProdHistoryLoading, setUatProdHistoryLoading] = useState(false);
    const [uatProdHistoryTotalElements, setUatProdHistoryTotalElements] = useState(0);
    const [uatProdHistoryList, setUatProdHistoryList] = useState([]);

    const [uatVersionLoading, setUatVersionLoading] = useState(false);
    const [uatVersionList, setUatVersionList] = useState([]);

    const [knowledgeRateLimit, setKnowledgeRateLimit] = useState(0);

    const getUatProdFileList = async params => {
        try {

            setLoading(true);

            const res = await UatProdService.getUatProdFileList(params);
            setUatProdFileList(res.data.content);
            setTotalElements(res.data.totalElements);

            setUatProdHistoryList([]);
            setUatProdHistoryTotalElements(0);

            setLoading(false);

        } catch (error) {

            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getUatProdHistoryList = async params => {
        try {

            setUatProdHistoryLoading(true);

            const res = await UatProdService.getUatProdFileList(params);
            setUatProdHistoryList(res.data.content);
            setUatProdHistoryTotalElements(res.data.totalElements);
            setKnowledgeRateLimit(res.headers.total);

            setUatProdHistoryLoading(false);

        } catch (error) {

            setUatProdHistoryLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getUatVersionList = async params => {
        try {

            setUatVersionLoading(true);

            const res = await UatProdService.getUatVersionList(params);
            setUatVersionList(res.data);

            setUatVersionLoading(false);

        } catch (error) {

            setUatVersionLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <UatProdFileContext.Provider
            value={{
                loading,
                totalElements,
                uatProdFileList,
                getUatProdFileList,
                uatVersionLoading,
                uatVersionList,
                getUatVersionList,
                uatProdHistoryLoading,
                uatProdHistoryTotalElements,
                uatProdHistoryList,
                getUatProdHistoryList,
                setUatProdHistoryList,
                knowledgeRateLimit,
            }}
        >
            {children}
        </UatProdFileContext.Provider>
    );
}

export default UatProdFileContextProvider;
