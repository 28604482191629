import * as API_URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";

export default class SubscriptionService {

    static getAllSubscriptionPlan = () =>
        privateAPI.get(API_URL.GET_ALL_SUBSCRIPTION_PLAN);

    static getSubscriptionPlanSummary = params =>
        privateAPI.get(API_URL.GET_CURRENT_SUBSCRIPTION_PLAN_SUMMARY, {params});

    static getSubscriptionPlan = params =>
        privateAPI.get(API_URL.GET_CURRENT_SUBSCRIPTION_PLAN, {params});

    static upgradeSubscriptionPlan = data =>
        privateAPI.post(API_URL.SUBSCRIPTION_PLAN_UPGRADE, data);

    static cancelSubscriptionPlan = data =>
        privateAPI.post(API_URL.CANCEL_SUBSCRIPTION_PLAN, data);

}
